<template>
    <div class="horizontal-wrapper">
        <div v-if="value.type != 'seperator'" class="horizontal-wrapper" >
            <div class="placeholder"></div>
            <h4>{{value.name}}</h4>
            <div class="placeholder"></div>
            <md-field v-if="value.type != 'Boolean'">
                <label for="">value</label>
                <md-input v-model="value.value"></md-input>
            </md-field>
            <md-switch v-if="value.type == 'Boolean'" v-model="value.value"></md-switch>
            <div class="placeholder"></div>
        </div>

        <div v-if="value.type == 'seperator'" class="horizontal-wrapper" >
            <div class="placeholder"></div>
            <h3>{{value.name}}</h3>
            <div class="placeholder"></div>
        </div>
        
    </div>
</template>
<script>
export default {
    name: 'global-values',
    props: {'value':Object},

}
</script>