<template>
    <div>
        <div v-ripple v-on:click="showDialog = true">
            {{ticket.name}}
        </div>
        <md-dialog :md-active.sync="showDialog" >
            <div style="min-height: 400pt; min-width: 650pt; ">

                <div class="horizontal-wrapper" style="flex: 0 0 auto;">
                    <md-dialog-title style="display: inline">Support Ticket</md-dialog-title>
                    <md-dialog-actions>
                        <md-button class="md-primary" @click="showDialog = false; $emit('ticket-close')">Close</md-button>
                        <md-button class="md-primary" @click="saveTicket">Save</md-button>
                    </md-dialog-actions>
                </div>
                <div class="vertical-wrapper" style="margin: 20px 30px">
                    <md-field>
                        <label for="">Kurzinfo</label>
                        <md-input v-model="ticket.name"/>
                    </md-field>
                    <md-field>
                        <label for="">Support Gruppe</label>
                        <md-select v-model="ticket.spezifikation">
                            <md-option value="v">Vertraglich</md-option>
                            <md-option value="t">Technisch</md-option>
                            <md-option value="s">Entwickler</md-option>
                            <md-option value="s">Sonstige</md-option>
                        </md-select>
                    </md-field>
                    <md-field>
                        <label for="">Priorität</label>
                        <md-select v-model="ticket.prio">
                            <md-option value="high">hoch</md-option>
                            <md-option value="middle">mittel</md-option>
                            <md-option value="low">niedirg</md-option>
                        </md-select>
                    </md-field>
                    <md-field >
                        <label for="">Beschreibung</label>
                        <md-textarea v-model="ticket.comment"></md-textarea>
                    </md-field>
                </div>
                
                
            </div>
        </md-dialog>
    </div>
</template>
<script>
export default {
    name: 'support-ticket',
    props: {ticket: Object},
    methods: {
        saveTicket(){

        }
    },
    data(){
        return{
            showDialog: this.$props.ticket.edit,
        }
    }
}
</script>