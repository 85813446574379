<template>
    <div class="vertical-wrapper" style="">
        <div class="header horizontal-wrapper" style="flex: 0 0 auto">
            <h2>TM Management</h2>
        </div>
        <div class="horizontal-wrapper" :class="{'hide':!showApplicationSelector}" style="flex: 0 0 auto"> 
            <div></div>
            <md-field style="flex: 0 0 auto; width: auto">
                <label for="" >Application</label>
                <md-select v-model="currentAplication" v-on:input="changeApplication">
                    <md-option :value="-1">neue Anwendung</md-option>
                    <md-option v-for="a in applications" :key="a.key" :value="a.id">{{a.name}}</md-option>
                </md-select>
            </md-field>
            <div></div>
            <md-button class="md-primary md-raised" v-on:click="$router.push({name: 'login-view'})">Logout</md-button>

        </div>
        <div style="overflow-y: scroll; padding: 0pt 7.5%;">
            <router-view >

            </router-view>
        </div>
        
        <md-bottom-bar style="flex: 0 0 auto; z-index:10" class="footer" md-type="shift"  :md-sync-route="true"  :options="{}"  >
            <md-bottom-bar-item id="my-app" :to="{name: 'my-application-view'}" md-label="meine Zeiterfassung" :md-icon="require('../assets/images/mobile-screen-button-solid.svg')"  ></md-bottom-bar-item>
            <md-bottom-bar-item id="payment" :to="{name: 'payment-view'}" md-label="Bezahlung" :md-icon="require('../assets/images/sack-dollar-solid.svg')"  ></md-bottom-bar-item>
            <md-bottom-bar-item id="backup" :to="{name: 'backup-view'}" md-label="Backups" :md-icon="require('../assets/images/database-solid.svg')"  ></md-bottom-bar-item>
            <md-bottom-bar-item id="support" :to="{name: 'support-view'}" md-label="Support" :md-icon="require('../assets/images/headset-solid.svg')"  ></md-bottom-bar-item>
           
            <md-bottom-bar-item v-if="isadmin" id="demo-access" :to="{name:'demo-access-view'}" md-label="Demo Zugänge" :md-icon="require('../assets/images/qrcode-solid.svg')"   ></md-bottom-bar-item>
            <md-bottom-bar-item v-if="isadmin" id="dashboard" :to="{name:'dashboard-view'}" md-label="Dashboard" :md-icon="require('../assets/images/chart-line-solid.svg')"  ></md-bottom-bar-item>
    
        </md-bottom-bar>
    </div>
</template>
<script>
import { v4 as uuidv4 } from 'uuid';

export default {
    name: 'main-view',
    methods:{
        changeApplication(){
            console.log('application id', this.currentAplication);
            this.$store.commit('set-application-id', this.currentAplication)
        }
    },
    computed:{
        token(){
            return this.$store.getters.token
        },

        isadmin(){
            return this.$store.getters.isadmin
        },
        showApplicationSelector(){
            return this.$store.getters.getShowApplicationSelector
        }
    },

    sockets:{
        connect(){
            this.$socket.emit('get-applications', {token: this.token})
        },
        'set-applications':function({applications}){
            applications.forEach(e => e.key = uuidv4());
            console.log(applications);
            this.applications = applications;
        }
    },
    data(){
        return{
            currentAplication: -1,
            applications: []

        }
    }
}
</script>