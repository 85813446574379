<template>
    <div class="vertical-wrapper">
        <h2>Bezahlung</h2>

        <h3>Bezahlmethode</h3>
        <div>

        </div>

        <h3>Rechnungen</h3>
        <div>
            
        </div>
    </div>
</template>
<script>
export default {
    name: 'payment-view',
    beforeRouteEnter (to, from, next) { 
        next(vm => { 
            vm.$socket.emit('get-payment-info', {token: vm.token, app:vm.app})
            vm.$store.commit('set-show-application-selector', true)
            next();
        }) 
    },
}
</script>