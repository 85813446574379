<template>
    <div>
        <h2>Backups</h2>
        <div class="vertical-wrapper">
            <div v-for="b in backups" :key="b.key" class="horizontal-wrapper" >
                <div class="placeholder"></div>
                <h4>{{b.name}}</h4>
                <div class="placeholder"></div>
                <div 
                    :style="`flex: 0 0 25pt; cursor:pointer; background-image:url(${require('../../assets/images/rotate-left-solid.svg')})`" 
                    class="icon"
                    v-on:click="requestRestoreBackup(b)"
                    >
                </div>
                <div class="placeholder"></div>

            </div>
        </div>
    </div>
</template>
<script>
import { v4 as uuidv4 } from 'uuid';

export default {
    name: 'backup-view',
    methods: {
        requestRestoreBackup(b){
            this.$socket.emit('request-restore-backup', {token: this.token, backup: b})
            this.$swal.fire({
                icon: 'warning',
                title: 'Backup wieder herstellen',
                text: 'Alle Daten bis zum Backupdatum werden unwiederruflich gelöscht, \ndamit dies nicht ausversehen passiert bestätigen sie diese Aktion mit dem Code aus unser E-mail',
                input: 'text',
                inputAttributes: {
                    autocapitalize: 'off'
                },
                showCancelButton: true,
                confirmButtonText: 'Bestätigen',
            }).then(e => {
                console.log(e);
                //this.$socket.emit('restore-backup', {token: this.token, backup: b, code:e.value})
            })
           
        }
    },
    computed:{
        token(){
            return this.$store.getters.token
        },

        isadmin(){
            return this.$store.getters.isadmin
        },
        applicationId(){
            return this.$store.getters.getApplicationId
        }
    },
    beforeRouteEnter (to, from, next) { 
        next(vm => { 
            vm.$socket.emit('get-backups', {token: vm.token, app:{id: vm.applicationId}});
            vm.$store.commit('set-show-application-selector', true)

            next();
        }) 
    },
    sockets: {
        'set-backups': function({backups}){
            this.backups = backups
        }
    },
    data(){
        return {
            backups: [{
                id: -1, name: 'Backup vom 2022-07-22', key:uuidv4()
            }]
        }
    }
}
</script>