<template>
    <div>
        <loginpage open_at_start="true" v-on:login="login" v-on:register="register"/>
    </div>
</template>
<script>
import loginpageVue from '../components/VueComponents/loginpage.vue'
export default {
    name: 'login-view',
    methods: {
        login({email, password}){
            this.$socket.emit('login', {email:email, password:password})
        },
        register({email, name, password}){
            this.$socket.emit('register', {name:name, email:email, password:password})
        }
    },
    components: {
        'loginpage':loginpageVue
    },
    sockets:{
        'login-failed':function(){
            this.$swal.fire({
                title: 'Oops',
                text: 'Login failed',
                icon: 'error'
            })
        }
    }
}   
</script>