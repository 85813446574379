<template>
    <div class="vertical-wrapper">
        <h2>Demo Zugänge</h2>
        <div class="vertical-wrapper">
            <div ref="demo-access-spreadsheet"></div>
        </div>
        <div class="horizontal-wrapper">
            <div></div>
            <md-button class="md-primary" v-on:click="addEntry">add</md-button>
            <div></div>
        </div>
        <md-dialog :md-active.sync="showQRDialog" >
            <div style="min-height: 400pt; min-width: 650pt; ">

                <div class="horizontal-wrapper" style="flex: 0 0 auto;">
                    <md-dialog-title style="display: inline">QR Codes</md-dialog-title>
                    <md-dialog-actions>
                        <md-button class="md-primary" @click="showQRDialog = false;">Close</md-button>
                    </md-dialog-actions>
                </div>
                <div class="horizontal-wrapper">
                    <div class="vertical-wrapper" v-for="(key, i) in keys" :key="key">
                        <qrcode-vue style="margin:auto" :value="key" :size="size" level="H" />
                        <a>{{key}}</a>

                        <h1>{{`${!i? 'Admin':'User'} Zugang`}}</h1>
                    </div>

                </div>
                
            </div>
        </md-dialog>
    </div>
</template>
<script>
import QrcodeVue from 'qrcode.vue'
import jexcelStyle from "jexcel/dist/jexcel.css"; // eslint-disable-line no-unused-vars
import jexcel from "jexcel"; // eslint-disable-line no-unused-vars

export default {
    name: 'demo-access-view',
    components: {'QrcodeVue':QrcodeVue},
    methods: {
        openQRCodeDisplay(e){
            const rowIndex = e.getAttribute("data-y");
            const accessID = this.spreadsheet.getData()[rowIndex][0]
            this.$socket.emit('get-demo-access-qrcode', {token: this.token, access:{id: accessID}})
        },
        sendMail(item){
            this.$swal.fire({
                title: 'Absenden?',
                text: 'Mit der Bestätigung versendest du eine Email',
                icon: 'question',
                showConfirmButton: true,
                confirmButtonText: 'Bestätigen',
                showDenyButton: true,

            }).then(e => {
                if(e.isConfirmed){
                    const rowIndex = item.getAttribute("data-y");
                    const accessID = this.spreadsheet.getData()[rowIndex][0]
                    this.$socket.emit('send-demo-access', {token: this.token, access:{id: accessID}})
                }
            })
           
        },
        onafterchanges(){
            console.log(this.spreadsheet)
            const demo_access = this.spreadsheet.getData()
            this.$socket.emit('edit-demo-access',{token:this.token, access: demo_access.map(e => {return {id: e[0], company:e[1], email:e[2], phone:e[3], form:e[4], name:e[5]}} )});
        },
        addEntry(){
            this.spreadsheet.insertRow([-1, '', '', '', '', '', 'open', 'send', 0, 0 ])
        }
    },
    beforeRouteEnter (to, from, next) { 
        next(vm => { 
            vm.$socket.emit('get-demo-access', {token: vm.token,})
            vm.$store.commit('set-show-application-selector', false)

            next();
        }) 
    },
    sockets: {
        'set-demo-access':function({access}){
            this.access = access.map(e => [e.id, e.company, e.email, e.phone, e.cform, e.name, 'open', 'send', e.count, this.$moment(e.lastdate).format('YYYY-MM-DD HH:mm')])
            if(this.access.length){
                this.spreadsheet.setData(this.access)
                const [x, y] = this.selection
                this.selection.length? this.spreadsheet.updateSelectionFromCoords(x, y, x, y): 0
            }
            else{
                this.$swal.fire({
                    icon: 'info',
                    title: 'no date',
                    text: 'keine Zugänge angelegt'
                })
            }
        }, 
        'set-demo-access-qrcode':function({keys}){
            
            this.keys = keys.map(e => e = `https://timedemo.frappgmbh.de/login-via-url/${e.token}`)
            this.showQRDialog = true
        }
    },
    computed:{
        token(){
            return this.$store.getters.token
        },

        isadmin(){
            return this.$store.getters.isadmin
        },
        app(){
            return this.$store.getters.application
        }
    },
    data(){
        return{
            spreadsheet: {},
            keys: [],
            showQRDialog: false,
            size: 200,
            selection: []
        }
    },
    mounted(){
        this.spreadsheet = jexcel(this.$refs["demo-access-spreadsheet"], { 
            data: [[-1, '', '', '', '', '', `open`, `send`]],
            columns:[
                { title:'id', width:0, type: 'hidden' },
                { title:'name', width:180 },
                { title:'email', width:180 },
                { title:'phone', width:180 },
                { title:'contact form', width:180 },
                { title:'contact name', width:180 },
                { title:'QR Code', width:120, editor: {
                    openEditor: this.openQRCodeDisplay,
                    closeEditor: () => {}
                 
                 }  
                },
                { title:'Message', width:120, editor: {
                    openEditor: this.sendMail,
                    closeEditor: () => {}
                 
                 }    },

                { title:'used', width:100 },
                { title:'last used', width:130 },

            ],
            onafterchanges:this.onafterchanges,
            onselection:(html_div, x, y)=>{
                this.selection = [x, y]
            }

        });
        
    }
}
</script>