<template>
  <div id="app" class="vertical-wrapper" style="min-height: 100vh;">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {

  },
  computed:{
    token(){
      return this.$store.getters.token
    }
  },
  sockets:{
    connect: function(){
      this.$socket.emit('check-token', {token: this.token})
    },
    'login-success':function({token}){
        this.$store.commit('set-token', token)
        this.$router.push({name: 'main'})
        this.$socket.emit('get-applications', {token: token})

    },
    'show-login':function(){
      this.$router.push({name: 'login'})
    },
    'set-admin':function(admin){
      this.$store.commit('set-admin', admin)
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
