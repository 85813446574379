<template>
    <div class="vertical-wrapper">
        <h2>Support</h2>

        <div class="horizontal-wrapper">
            <md-field v-if="isadmin">
                <label for="">Kunden</label>
                <md-select v-model="supportTicketCustomerFilter">
                    <md-option :value="-1">alle</md-option>
                    <md-option v-for="c in customers" :key="c.key" :value="c.id">{{c.name}}</md-option>
                </md-select>
            </md-field>
            <div v-if="isadmin" style="flex: 0 0 20pt"></div>
            <md-field>
                <label for="">suchen</label>
                <md-input v-model="searchString"></md-input>
            </md-field>
            <div style="flex: 0 0 20pt"></div>
            <md-field>
                <label for="">tickets</label>
                <md-select v-model="supportTicketStatusFilter">
                    <md-option value="open">offen</md-option>
                    <md-option value="closed">geschlossen</md-option>
                    <md-option value="all">alle</md-option>
                </md-select>
            </md-field>
        </div>
        <div class="vertical-wrapper">
            <div v-if="!tickets.length" class="horizontal-wrapper">
                <div></div>
                <h2 style="color: grey">keine Tickets</h2>
                <div></div>
            </div>
            <div class="horizontal-wrapper">
                <div></div>
                <md-button class="md-primary" v-on:click="tickets.push({id: -1, name: '', key:uuidv4(), comment: '', prio:'low', group:'s', edit:true})">erstellen</md-button>
                <div></div>
            </div>
            <support-ticket-item v-for="t in tickets" :key="t.key" :ticket="t"></support-ticket-item>
        </div>
    </div>
</template>
<script>
import { v4 as uuidv4 } from 'uuid';

import supportTicketItemVue from '../../components/support-ticket.vue'

export default {
    name: 'support-view',
    components: {
        'support-ticket-item':supportTicketItemVue
    },
    methods:{
        uuidv4(){
            return uuidv4()
        }
    },
    computed:{
        token(){
            return this.$store.getters.token
        },

        isadmin(){
            return this.$store.getters.isadmin
        }
    },
    beforeRouteEnter (to, from, next) { 
        next(vm => { 
            vm.$socket.emit('get-support-tickets', {token: vm.token, app:vm.app})
            vm.$store.commit('set-show-application-selector', false)
            next();
        }) 
    },
    data(){
        return{

            supportTicketStatusFilter: 'open',
            tickets: [],
            supportTicketCustomerFilter: -1,
            customers: [
                {id: 0, name:'Enwat Gmbh',}
            ],
            searchString: ''
        }
    }

}
</script>