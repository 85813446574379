import VueRouter from 'vue-router'

import loginView from '@/views/login-view.vue'
import tokenConfirmationView from '@/views/token-confirmation-view.vue'
import mainView from '@/views/main-view.vue'

//user views
import myApplication from '@/views/user-views/my-application-view.vue'
import paymentView from '@/views/user-views/payment-view.vue'
import backupView from '@/views/user-views/backup-view.vue'
import supportView from '@/views/user-views/support-view.vue'

//admin views
import demoAccessView from '@/views/admin-views/demo-access-view.vue'
import dashboardView from '@/views/admin-views/dashboard-view.vue'



export default new VueRouter({
    mode: 'history',
    routes: [
        { path: '/', redirect: {name : 'login'}, },
        { path: '/login', name: 'login', component: loginView },
        { path: '/token-confirmation/:token', name: 'token-confirmation-view', props:true, component: tokenConfirmationView },

        {
            path: '/main', name: 'main', redirect: { name: 'my-application-view' }, component: mainView, children: [
            { path: '/my-application', name: 'my-application-view', component: myApplication },
            { path: '/payment', name: 'payment-view', component: paymentView },
            { path: '/backups', name: 'backup-view', component: backupView },
            { path: '/support', name: 'support-view', component: supportView },

            { path: '/demo-access', name: 'demo-access-view', component: demoAccessView },
            { path: '/dashboard', name: 'dashboard-view', component: dashboardView },

        ] 
        },


        { path: '*', template: '<p>404</P>' } // Not found
    ]
})