<template>
    <div class="vertical-wrapper">
        <h2>Dashboard</h2>
    </div>
</template>
<script>

export default {
    name: 'dashboard-view',
    beforeRouteEnter (to, from, next) { 
        next(vm => { 
            vm.$socket.emit('get-dashboard', {token: vm.token,})
            vm.$store.commit('set-show-application-selector', false)

            next();
        }) 
    },
    sockets: {
        'set-dashboard':function(){
        }
    },
    data(){
        return{
            spreadsheet: {}
        }
    },
 
}
</script>