<template>
    <div>

    </div>
</template>
<script>
export default {
    name: 'token-confirmation-view',
    props: { 'token':String },
    beforeRouteEnter (to, from, next) { 
        next(vm => { 
            vm.$socket.emit('confirm-token', {token: vm.token,})
            next();
        }) 
    },
}
</script>