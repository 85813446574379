
import Vue from 'vue'
import Vuex from 'vuex'
import cookie from 'vue-cookies'
Vue.use(Vuex)
const store = function createStore() {
    return new Vuex.Store({
        state: () => ({
            token: cookie.get('user-token'),
            admin: 0,
            showApplicationSelector: false,

            date: new Date(),
            app: { id: -1, name: '' },
            currentApplicationId: -1

        }),
        mutations: {
            'set-token': function (state, token) {
                cookie.set('user-token', token)
                state.token = token;
            },
            'set-constant': function (state, { name, value }) {
                state[name] = value
            },
            'set-admin': function (state, admin) {
                state.admin = admin
            },

            'set-date': function (state, date) {
                state.date = date
            },
            'set-application-id': function (state, id ) {
                state.currentApplicationId = id;
            },
            'set-show-application-selector': function(state, show){
                state.showApplicationSelector = show
            }
        },
        actions: {

        },
        getters: {
            token(state) {
                return state.token;
            },
            urlAccessToken(state) {
                return state.urlAccessToken;
            },
            isadmin(state) {
                return state.admin
            },
            getConstant: (state) => (name) => {
                return state[name]
            },

            getDate(state) {
                return state.date
            },

            getApplication(state) {
                return state.app
            },
            getShowApplicationSelector(state){
                return state.showApplicationSelector
            },
            getApplicationId(state){
                return state.currentApplicationId
            }
        }
    })
}
export default store;