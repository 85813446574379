import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

import './assets/layout.css'
import './assets/steuerelement.css'

import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);
Vue.prototype.$isMobile = function () {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return true
  } else {
    return window.innerWidth < 800
  }
};

import Ripple from 'vue-ripple-directive'

Vue.directive('ripple', Ripple);

//Socket.io
import IO from 'socket.io-client'
import VueSocketIO from 'vue-socket.io'
const optionsVueIO = {
  connection: IO(
  )
}
Vue.use(new VueSocketIO(optionsVueIO));

import VueRouter from 'vue-router'
import myrouter from '@/router/index.js'
Vue.use(VueRouter)
import createStore from '@/store/index.js'
Vue.use(VueMaterial)

Vue.use(require('vue-moment'));
new Vue({
  render: h => h(App),
  router: myrouter,
  store: createStore()
}).$mount('#app')
