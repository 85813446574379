<template>
    <div class="vertical-wrapper">
        <h2>meine Zeiterfassung</h2>
        <div class="horizontal-wrapper">
            <div class="placeholder"></div>
            <md-button class="md-primary md-raised" v-on:click="saveApplication">Speichern</md-button>
        </div>

        <h2>Name</h2>
        <div class="horizontal-wrapper">
            <md-field>
                <label for="">name</label>
                <md-input v-model="application.name"></md-input>
            </md-field>
            <div style="flex: 0 0 20pt"></div>
             <md-field>
                <label for="">url</label>
                <md-input v-model="application.url"></md-input>
            </md-field>
        </div>
        <h2>Logos</h2>
        <div class="horizontal-wrapper">
            <md-field>
                <label>Logo</label>
                <md-file v-model="application.logoName" v-on:change="(e) => image_change(e, 'logo')"/>
            </md-field>
            <div style="flex: 0 0 20pt"></div>
            <md-field>
                <label>App Icon</label>
                <md-file v-model="application.iconName" v-on:change="(e) => image_change(e, 'icon')"/>
            </md-field>
        </div>
         <h2>Theme</h2>
        <div class="horizontal-wrapper">
            <md-field>
                <label>Primär</label>
                <md-input type="color" v-model="application.primaryColor"/>
            </md-field>
            <div style="flex: 0 0 20pt"></div>
            <md-field>
                <label>Sekundär</label>
                <md-input type="color" v-model="application.accentColor"/>
            </md-field>
        </div>
        <h2>Globale Einstellungen</h2>
        <global-values v-for="gv in globalValues" :key="gv.key" :value="gv"></global-values>
      

       
   
    </div>
</template>
<script>
import { v4 as uuidv4 } from 'uuid';
import globalValuesVue from '../../components/global-values.vue';

export default {
    name: 'my-application-view',
    components: {
        'global-values':globalValuesVue
    },
    methods: {
        saveApplication(){
            this.$swal.fire({
                icon: 'warning',
                title: 'Änderungen speichern?',
                text: `
                Änderungen hier haben Auswirkungen auf die Lauffähigkeit Ihrer Anwendung, \n
                sollten Sie Module hinzugebucht haben ändert dies Ihre Vertragsbedingungen.
                Um die Änderungen zu bestätigen geben Sie hier den Code ein, welchen Sie per E-Mail bekommen haben.`,
                input: 'text',
                inputAttributes: {
                    autocapitalize: 'off'
                },
                showCancelButton: true,
                confirmButtonText: 'Bestätigen',
            }).then(e => {
                if(e.isConfirmed)
                    this.$socket.emit('edit-application-details', {token: this.token, application: this.application, code:e.value, })
                //this.$socket.emit('restore-backup', {token: this.token, backup: b, code:e.value})
            }) 
        },
        image_change(e, source){
            if(!e.target.files.length)
                return;
            this.waitForFiles++
            const file = e.target.files[0]
            const reader = new FileReader();
            reader.addEventListener('load', (event) => {
                this.application[source] = new Uint8Array(event.target.result);
                this.waitForFiles--;
            });
            reader.readAsArrayBuffer(file);
        },
    },
    sockets: {
        'set-application-details':function({application, globalValues}){
            console.log(application);
            this.application = application
            globalValues.forEach(e => {
                e.key = uuidv4(); 
                if(e.type == 'Boolean'){
                    e.value = e.value? true: false;
                }
            });
            this.globalValues = globalValues
        },
        'set-global-values':function(){

        },
    },
    beforeRouteEnter (to, from, next) { 
        next(vm => { 
            vm.$socket.emit('get-application-details', {token: vm.token, app:vm.app})
            vm.$store.commit('set-show-application-selector', true)
            next();
        }) 
    },
    computed:{
        token(){
            return this.$store.getters.token
        },

        isadmin(){
            return this.$store.getters.isadmin
        },
        applicationId(){
            return this.$store.getters.getApplicationId
        }
    },
    watch:{
        applicationId(){
            if(this.applicationId < 0){
                this.application = { id: -1, name: '', url: '', logo: '', icon: '', primaryColor: '', accentColor: '', logoName: '', iconLogo: ''}
                return
            }
            this.$socket.emit('get-application-details', {token: this.token, application:{id: this.applicationId} })
        }
    },
    data(){
        return{
            globalValues: [
                { id: 0, name: 'Nachtschichten', value: true, type:'seperator', key: uuidv4()},
                { id: 0, name: 'night_shift_enabled', value: true, type:'Boolean', key: uuidv4()},

            ],
            application:{
                id: -1, name: '', url: '', logo: '', icon: '', primaryColor: '', accentColor: '', logoName: '', iconLogo: ''
            },
            waitForFiles:0
        }
    }
}
</script>